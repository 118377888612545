import * as React from "react"
import { Link } from "gatsby"

// import { StaticImage } from 'gatsby-plugin-image';
import Button from "../components/Buttons"
import { H1, H2 } from "../components/Typography"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Pattern from "../components/Layout/Pattern"
import { Calendar, Clock, ExternalLink, Users } from "react-feather"
import useScroll from "../hooks/useScroll"
import culinaryOverSuicide from "../images/culinary-over-suicide.jpg"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const scrollTo = useScroll()

  return (
    <Layout>
      <Helmet>
        <meta
          name="og:url"
          content="https://www.cmkculinary.org/culinary-over-suicide-2021"
        />
        <meta name="og:image" content={culinaryOverSuicide} />
        <link
          rel="canonical"
          href="https://www.cmkculinary.org/culinary-over-suicide-2021"
        />
      </Helmet>
      <SEO
        title="COS 2.0 2021 | CMK Culinary"
        description="Join CMK on a 3 days free training event (Culinary Over Suicide) as we rekindle the hope of
        individuals or aspiring chefs by motivating and encouraging them ..."
      />
      <section className="container mx-auto min-h-screen pt-0 sm:py-24 flex flex-col md:flex-row items-start px-4">
        <div className="px-4 order-2 md:order-1">
          <span className="font-body font-bold text-2xl text-gray-900">
            #COS
            <span className="text-red-600">2.0</span>
          </span>
          <h1 className="text-4xl lg:text-7xl font-bold mb-12">
            <span className="font-serif text-gray-900">
              BOUNCING <br className="hidden md:inline-block" />
              <span className="text-red-600">BACK</span>
            </span>
          </h1>

          <div className="w-full max-w-xl select-none">
            <p className="text-lg font-bold leading-5 md:leading-auto md:text-2xl mb-4 md:mb-8 flex font-body text-gray-700">
              Rekindling hope in Culinary with <br /> 1 on 1 counselling,
              mentorship and Culinary training for FREE.
            </p>
            <p className="text-sm md:text-base text-gray-600">
              Join CMK on a 3 days free training event as we rekindle the hope
              of individuals or aspiring chefs by motivating and encouraging
              them to keep pushing. Hence, killing the thought of depression
              that leads to suicide and replacing hopelessness with the
              excitement of culinary practices.
            </p>
          </div>

          <section className="mt-12 select-none">
            <p className="text-sm sm:text-base md:text-xl mb-4 flex text-gray-700">
              <span className="inline-flex items-center">
                <Calendar className="opacity-50 mr-2" />
                <span className="text-red-600">24th - 26th </span>&nbsp;June.
              </span>
              <span className="ml-4 inline-flex items-center">
                <Clock className="opacity-50 mr-2" /> Starts&nbsp;
                <span className="text-red-600">10am Daily</span>
              </span>
            </p>

            <div className="flex flex-col md:flex-row mt-12">
              <Link
                to="https://forms.gle/DjZrPuS7t5ajMzxg6"
                referrerPolicy="no-referrer"
              >
                <Button
                  primary
                  className="w-full mb-4 md:mb-0 font-bold"
                  rounded
                >
                  <ExternalLink class="mr-2" />
                  <span className="md:py-2 md:px-4">REGISTER NOW!</span>
                </Button>
              </Link>
              {/* <Button.Secondary
                className="md:ml-4 font-bold hidden"
                onClick={() =>
                  scrollTo(0, 0, document.querySelector("#facilitators"))
                }
              >
                <Users class="mr-2" />
                <span className="md:py-2 md:px-4">SEE SPEAKERS</span>
              </Button.Secondary> */}
            </div>

            <p className="text-xs sm:text-sm mt-4 text-gray-600 max-w-lg mb-8">
              * Await for a confirmation message before the start date of the
              event. For more enquires our contact information are at the bottom
              of this page
            </p>
          </section>
        </div>
        <div className="max-w-lg lg:pl-12 mb-12 -mx-4 md:mx-0 order-1">
          <img
            src={culinaryOverSuicide}
            alt="Culinary Over Suicide Banner"
            className="md:rounded-lg w-full"
          />
        </div>
      </section>
      <Pattern size={32} className="w-full h-24 bottom-0" />
    </Layout>
  )
}

export default IndexPage
